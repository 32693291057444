<template>
	<div class="app-container scheduleInterview" v-loading="loading">
		<div class="titlebox">
			<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
			<div class="title">安排面试</div>
		</div>
		<div class="picbox">
			<div>
				<el-avatar :size="100" :src="'https://ehr-prod.oss-cn-shenzhen.aliyuncs.com/template/878b8e2202ca46bdbc479c7ebe1cd460.png'">
					<div slot="error" class="image-slot">
						<i class="el-icon-picture-outline"></i>
					</div>
				</el-avatar>
			</div>
			<div>
				<h2>{{ interForm.name }}</h2>
				<p>
					<span>手机号码：{{ interForm.phoneNumber }}</span>
				</p>
				<p>
					<span>邮箱：{{ interForm.mailbox }}</span>
				</p>
			</div>
		</div>
		<el-form :model="interForm" ref="form" class="viewbox" label-width="90px">
			<el-row>
				<el-col :xl="12" :lg="12" :md="12">
					<el-form-item label="面试岗位">
						<el-input v-model="interForm.jobName" placeholder="请输入面试岗位" disabled></el-input>
					</el-form-item>
				</el-col>
				<!--<el-col :offset="1" :xl="8" :lg="8" :md="10">
          <el-form-item label="面试时间">
            <el-date-picker v-model="interForm.interviewTime" type="datetime" default-time="[09:00:00]" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" placeholder="选择日期时间"></el-date-picker>
          </el-form-item>
        </el-col>!-->
				<el-col :xl="24" :lg="24" :md="24">
					<el-form-item label="面试地点">
						<el-select
							v-model="interForm.addressId"
							type="datetime"
							@change="addressToInfo"
							style="width: 60.6%; margin-right: 10px"
							placeholder="选择面试地点"
						>
							<el-option v-for="(address, i) in addressArr" :key="i" :label="address.label" :value="address.id"></el-option>
						</el-select>
						<el-button
							@click="
								addressdig = true;
								if ($refs.AddressForm != undefined) $refs.AddressForm.resetFields();
							"
							icon="el-icon-plus"
							>添加地址</el-button
						>
					</el-form-item>
				</el-col>
				<el-col :xl="12" :lg="12" :md="12">
					<el-form-item label="联系人">
						<el-input v-model="interForm.contacts" disabled></el-input>
					</el-form-item>
				</el-col>
				<el-col :xl="12" :lg="12" :md="12">
					<el-form-item label="联系人电话">
						<el-input v-model="interForm.contactsPhone" disabled></el-input>
					</el-form-item>
				</el-col>
				<el-col :xl="24" :lg="24" :md="24">
					<el-form-item label="面试官">
						<p v-for="(interview, i) in interForm.resumeInterviewDetailDtoList" :key="i">
							<span style="min-width: 80px; text-align: center; display: inline-block">{{ interview.interviewRoundsName }}</span>
							<span style="margin: 0 12px">|</span>
							<i class="el-icon-time" style="margin-right: 5px"></i>
							{{ interview.interviewTime }}
							<span style="margin: 0 12px">|</span>
							<i class="el-icon-user" style="margin-right: 5px"></i>
							{{ interview.nickName }}
							<img
								src="../../../assets/button/删除@3x.png"
								style="width: 14px; margin-left: 20px; transform: translateY(1px)"
								@click="deleteInterview(i)"
							/>
							<!-- <el-button
                type="danger"
                style="margin-left:20px"
                size="mini"
                icon="el-icon-delete"
                @click="deleteInterview(i)"
              ></el-button> -->
						</p>
						<el-button @click="addInterView" icon="el-icon-plus" size="small" style="margin-top: 5px">添加面试官</el-button>
					</el-form-item>
				</el-col>
			</el-row>
			<div class="btnbox">
				<el-button @click="$router.back()">取消</el-button>
				<el-button type="primary" @click="onSubmit()">提交</el-button>
			</div>
		</el-form>

		<el-dialog title="添加地址信息" :visible.sync="addressdig" width="500px" center>
			<el-form :model="AddressForm" ref="AddressForm" label-width="80px">
				<el-form-item label="地址名称" prop="addressName" :rules="{ required: true, message: '请输入地址名称', triggr: 'blur' }">
					<el-input v-model="AddressForm.addressName" placeholder="1-30个字符" maxlength="30"></el-input>
				</el-form-item>
				<el-form-item label="联系人" prop="name" :rules="{ required: true, message: '请输入联系人', triggr: 'blur' }">
					<el-input v-model="AddressForm.name" placeholder="1-30个字符" maxlength="30"></el-input>
				</el-form-item>
				<el-form-item label="联系电话" prop="phoneNum" :rules="{ required: true, message: '请输入联系电话', triggr: 'blur' }">
					<el-input v-model="AddressForm.phoneNum" placeholder="1-30个字符" maxlength="30"></el-input>
				</el-form-item>
				<el-form-item label="详细地址" prop="detailedAddress" :rules="{ required: true, message: '请输入详细地址', triggr: 'blur' }">
					<el-input
						v-model="AddressForm.detailedAddress"
						placeholder="请输入详细地址"
						type="textarea"
						maxlength="50"
						show-word-limit
						:rows="2"
					></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer">
				<el-button @click="addressdig = false">取 消</el-button>
				<el-button type="primary" @click="addAddress">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="添加面试信息" :visible.sync="interviewdig" width="40%" center>
			<el-form :model="interviewForm" ref="interviewForm" label-width="80px">
				<el-form-item label="面试轮次" prop="interviewId" :rules="{ required: true, message: '请选择面试轮次', trigger: 'change' }">
					<el-select v-model="interviewForm.interviewId" placeholder="请选择面试轮次" style="width: 100%">
						<el-option v-for="(item, i) in options" :key="i" :label="item.interviewRoundsName" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="面试时间" prop="interviewTime" :rules="{ required: true, message: '请选择面试时间', trigger: 'change' }">
					<el-date-picker
						style="width: 100%"
						:picker-options="pickerOptions"
						v-model="interviewForm.interviewTime"
						type="datetime"
						default-time="[09:00:00]"
						value-format="yyyy-MM-dd HH:mm"
						format="yyyy-MM-dd HH:mm"
						placeholder="选择日期时间"
					></el-date-picker>
				</el-form-item>
				<el-form-item label="面试官" prop="staffId" :rules="{ required: true, message: '请选择面试官', trigger: 'change' }">
					<el-select v-model="interviewForm.staffId" placeholder="请选择面试官" style="width: 100%" filterable :loading="loading">
						<el-option v-for="(item, index) in dutyUsers" :key="index" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" style="text-align: center">
				<el-button @click="interviewdig = false">取 消</el-button>
				<el-button type="primary" @click="confirmAdd">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import {
	interviewDetail,
	configAddressList,
	configAddressSave,
	interviewSave,
	selectPrincipallist,
	configRoundList
} from '@/api/recruitment_allocation';
export default {
	data() {
		return {
			addressArr: [],
			interForm: {
				avatarUrl: '',
				name: '',
				areaCode: '86',
				phoneNumber: '',
				mailbox: '',
				jobName: '',
				// interviewTime: "",
				addressId: '',
				contacts: '',
				contactsPhone: '',
				resumeInterviewDetailDtoList: []
			},
			AddressForm: {
				name: '',
				addressName: '',
				phoneNum: '',
				detailedAddress: ''
			},
			pickerOptions: {
				disabledDate: (time) => time.getTime() + 86400000 < new Date()
			},
			options: [],
			dutyUsers: [],
			interviewForm: {
				interviewId: '',
				interviewTime: '',
				staffId: ''
			},
			addressdig: false,
			loading: false,
			interviewdig: false
		};
	},
	mounted() {
		this.getConfigAddress();
		this.getinterviewDetail();
	},
	methods: {
		getConfigAddress(val) {
			configAddressList({ pageNo: 1, pageSize: 99999 }).then((res) => {
				this.addressArr = [];
				if (res.list) {
					res.list.length &&
						res.list.map((it) => {
							this.addressArr.push({
								...it,
								label: `${it.detailedAddress}(${it.addressName})`
							});
							if (val && it.id == val) {
								this.interForm.addressId = it.id;
								this.interForm.contacts = it.name;
								this.interForm.contactsPhone = it.phoneNum;
							}
						});
				}
			});
		},
		addressToInfo(val) {
			this.addressArr.map((it) => {
				if (it.id == val) {
					this.interForm.contacts = it.name;
					this.interForm.areaCode = '86'; // 先取个默认的86
					this.interForm.contactsPhone = it.phoneNum;
				}
			});
		},
		addAddress() {
			this.$refs.AddressForm.validate((valid) => {
				if (valid) {
					configAddressSave({ ...this.AddressForm }).then((res) => {
						if (res && res._responseStatusCode == 0) {
							this.$message.success('添加地址信息成功');
							this.getConfigAddress(String(res.id));
							this.addressdig = false;
						}
					});
				}
			});
		},
		addInterView() {
			this.options = [];
			this.dutyUsers = [];
			selectPrincipallist({}).then((res) => {
				res.map((it) => {
					this.dutyUsers.push({
						label: `${it.staffName} ${it.officeName ? `(${it.officeName})` : ''}`,
						value: String(it.staffId)
					});
				});
			});
			configRoundList({ status }).then((res) => {
				res.map((it) => {
					if (it.status == '2') {
						this.options.push(it);
					}
				});
			});
			if (this.$refs.interviewForm != undefined) {
				this.$refs.interviewForm.resetFields();
			}
			this.interviewdig = true;
		},
		deleteInterview(index) {
			this.interForm.resumeInterviewDetailDtoList.splice(index, 1);
		},
		onSubmit(params) {
			if (this.interForm.addressId == '' || !this.interForm.addressId) {
				this.$message.error('请选择面试地点');
				return false;
			}
			if (this.interForm.resumeInterviewDetailDtoList.length <= 0) {
				this.$message.error('请至少选择一位面试官');
				return false;
			}
			this.$confirm(`确定为${this.interForm.name}安排面试?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.loading = true;
					interviewSave(
						params
							? params
							: {
									...this.interForm,
									resumeInterviewDetailDtoList: [],
									recruitInterviewProcessDtoList: this.interForm.resumeInterviewDetailDtoList,
									resumeId: this.$route.params.resumeId
							  }
					).then((res) => {
						this.loading = false;
						if (res.code == 0) {
							if (!params) {
								this.$message.success('面试安排提交成功');
								this.$router.push({ name: 'Resumelibrary' });
							} else {
								this.$message.success('新增面试官成功');
								this.getinterviewDetail();
							}
						}
					});
				})
				.catch(() => {});
		},
		confirmAdd() {
			const _list = this.interForm.resumeInterviewDetailDtoList;

			for (const key in this.interForm.resumeInterviewDetailDtoList) {
				if (_list[key].interviewId == this.interviewForm.interviewId && _list.length > 0) {
					this.$message.error('不能存在相同的面试轮次');
					this.interviewForm.interviewId = '';
					return false;
				}
			}
			if (_list.length > 0 && _list[_list.length - 1].interviewTime >= this.interviewForm.interviewTime) {
				this.$message.error('后一轮面试时间要比前一轮晚');
				this.interviewForm.interviewTime = '';
				return false;
			}
			this.$refs.interviewForm.validate((valid) => {
				if (valid) {
					this.options.map((it1) => {
						if (this.interviewForm.interviewId == it1.id) {
							this.interviewForm.interviewRoundsName = it1.interviewRoundsName;
						}
					});
					this.dutyUsers.map((it2) => {
						if (this.interviewForm.staffId == it2.value) {
							this.interviewForm.nickName = it2.label;
						}
					});
					this.interForm.resumeInterviewDetailDtoList.push(JSON.parse(JSON.stringify(this.interviewForm)));
					// let recruitInterviewProcessDtoList=this.interForm.resumeInterviewDetailDtoList;
					// let params={
					//   resumeId:this.$route.params.resumeId,
					//   addressId:this.interForm.addressId,
					//   // interviewTime:this.interForm.interviewTime,
					//   recruitInterviewProcessDtoList
					// }
					// this.onSubmit(params);
					this.interviewdig = false;
				}
			});
		},
		getinterviewDetail() {
			interviewDetail({ resumeId: this.$route.params.resumeId }).then((res) => {
				if (res && res._responseStatusCode == 0) {
					delete res._responseStatusCode;
					this.interForm = res;
					if (this.interForm.addressId) {
						this.addressToInfo(this.interForm.addressId);
					}
				}
			});
		},
		getUserData() {
			this.loading = false;
		}
	}
};
</script>
<style lang="scss">
.scheduleInterview {
	.picbox {
		padding: 0px 0 0 100px;
		background: #fff;
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		height: 140px;
		:first-child {
			margin-right: 10px;
		}
		h2 {
			margin: 10px 0 0 0;
		}
		p {
			margin: 15px 0px;
			color: #999999;
		}
	}
	.viewbox {
		font-size: 14px;
		padding: 30px 6%;
		background-color: #fff;
		.el-form-item {
			.el-input {
				width: 230px;
			}
			.el-select {
				.el-input {
					min-width: 100%;
					width: 100%;
				}
			}
		}
	}
	.btnbox {
		text-align: center;
		margin-top: 30px;
		padding: 30px;
		background-color: #fff;
	}
	.el-dialog__footer {
		text-align: center;
	}
}
</style>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
</style>
